import * as React from 'react'
import { Link } from 'gatsby'

const IndexPage = () => {
  return (
    <main>
      <div>hello</div>
      <Link to="/app/signup">Sign Up</Link>
    </main>
  )
}

export default IndexPage
